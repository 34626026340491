@font-face {
  font-family: 'Didot';
  src: local('Didot'), url('./fonts/Didot_Regular.ttf') format('truetype');
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: 'Didot-bold';
  src: local('Didot'), url('./fonts/Didot_LT_Std_Bold.otf') format('truetype');
  font-style: bold;
  font-weight: 700;
}

@font-face {
  font-family: 'Didot-italic';
  src: local('Didot'), url('./fonts/Didot_Bold_Italic.ttf') format('truetype');
  font-style: italic;
  font-weight: 400;
}

body {
  margin: 0;
  font-family: 'Didot', serif;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
/* 
@keyframes bounce {
  0%, 100% { transform: translateY(0); }
  50% { transform: translateY(-20px); }
}

.scroll-down-indicator {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  animation: bounce 2s infinite;
  z-index: 300;
  color: 'white';
}

.scroll-down-indicator::before {
  content: '⬇';
} */
/* 
.scroll {
  color:#000;
  width: 60px;
  height: 60px;
  border: 2px solid #333;
  border-radius: 50%;
  position: relative;
  animation: down 1.5s infinite;
  -webkit-animation: down 1.5s infinite;
  z-index: 30;
}

.scroll::before {
  content: '';
  color:#000;
  position: absolute;
  top: 15px;
  left: 18px;
  width: 18px;
  height: 18px;
  border-left: 2px solid #333;
  border-bottom: 2px solid #333;
  transform: rotate(-45deg);
} */

.submenu-item {
  cursor: pointer;
  color: white;
  transition:cubic-bezier(1, 1, 1, 1) 0.3s;
}

.submenu-item:hover {
  cursor: pointer;
  color: yellow;
  transition:cubic-bezier(1, 1, 1, 1) 0.3s;
}

.menu-item:hover {
  cursor: pointer;
  color: yellow;
  transition:cubic-bezier(1, 1, 1, 1) 0.3s;
}

.hotspot-button {
  background: rgba(60, 180, 229, 0.65);
  backdrop-filter: blur(5px);
  font-size: 10px;
  color: #000;
  min-width: 100px;
  padding: '12px';
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  border: none;
  box-shadow: #00000050 0px 0px 10px;
  transition:cubic-bezier(1, 1, 1, 1) 0.3s;
}

.hotspot-button:hover{
  cursor: pointer;
  background: rgba(60, 180, 229, 0.9);
  transition:cubic-bezier(1, 1, 1, 1) 0.3s;
}

.hotspot-button[data-isOpen="true"] {
  width: 400px;
  height: 200px;
}

.child {
  width: 40px;
  height: 40px;
  background: #f107a3;
  border-radius: 50%;
}

.close-button:hover{
  cursor: 'pointer !important',
}

.image-container {
  margin: 5rem 0;
  width: 60vw;
  height: 60vw;
  overflow: hidden;
  /* background-color: pink; */
}

img {
  width: 100%;
  scale: 1.3;
}

.section1{
  background-color: rgb(255, 255, 255, 0.5);
  font-family: 'Didot';
}

.scroll-down-container {
  width: 100%;
  position: fixed; /* Make the container float over the content */
  bottom: 64px; /* Position it 64px from the bottom */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 30; /* Make sure it appears above other content */
} 

.scroll-down {
  width: 60px;
  height: 60px;
  border: 2px solid #fff;
  border-radius: 50%;
  animation: down 1.5s infinite;
  -webkit-animation: down 1.5s infinite;
}

.scroll-down::before {
  content: '';
  position: absolute;
  top: 15px;
  left: 20px;
  width: 18px;
  height: 18px;
  border-left: 2px solid #fff;
  border-bottom: 2px solid #fff;
  transform: rotate(-45deg);
}






@keyframes down {
	0% {
		transform: translate(0);
	}
	20% {
		transform: translateY(15px);
	}
	40% {
		transform: translate(0);
	}
}

@-webkit-keyframes down {
	0% {
		transform: translate(10px);
	}
	20% {
		transform: translateY(25px);
	}
	40% {
		transform: translate(10px);
	}
}